import { useState, useEffect } from "react";
import { Box, Grid, Typography, ListItem, ListItemButton, ListItemText, Modal, Button } from "@mui/material";
import Countdown from 'react-countdown';
import { largeModalStyle } from "../utils/styles";
import { loadProgram, getNetworkConnection, fetchVoteCountForProposal, emptyAnchorWallet } from "../utils/utils";
import ProposalCard from "./ProposalCard";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Colors, Fonts } from "../utils/styles/DefaultTheme";
import closeImg from "../assets/images/close.png";
import { useSearchParams } from "react-router-dom";


const ProposalListItem = (props: any) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [totalVotes, setTotalVotes] = useState(0);

    const anchorWallet = useAnchorWallet();
    const connection = getNetworkConnection(30);

    const [params, setParams] = useSearchParams();

    useEffect(() => {
        getTotalVotes();
        handleProposalParam();
    }, []);

    useEffect(() => {
        // console.log(props.proposal.account)
        getTotalVotes();
    }, [props.proposal]);

    const getTotalVotes = async() => {
        // let wallet = (!anchorWallet || !anchorWallet.publicKey) ? emptyAnchorWallet : anchorWallet;
        setTotalVotes(props.proposal.account.tally.toNumber());

        // const total = await fetchVoteCountForProposal(wallet, props.proposal.publicKey);
        // const total = await fetchVoteCountForProposal(props.proposal.choices);

    //    setTotalVotes(total);
    }

    const handleModalClose = () => {
        // remove key param
        const proposalKey = params.get("key"); 

        if (proposalKey) {
            params.delete("key");
            params.delete("type");
            setParams(params);
        }

        props.loadProposals();
        setModalOpen(false);
    }

    const handleModalOpen = () => {
            params.set("key", props.proposal.publicKey.toString());
            setParams(params);

        setModalOpen(true);
    }

    const handleProposalParam = () => {
        const proposalKey = params.get("key"); 

        if (proposalKey && proposalKey === props.proposal.publicKey.toString()) {
            setModalOpen(true);
        }
      }

    const upcomingRenderer = (props: any) => {
        if (props.days < 1) {
            return <span>Starts in {props.hours}h, {props.minutes}m, {props.seconds}s</span>;
        }

        if (props.days < 1 && props.hours < 1) {
            return <span>Starts in {props.minutes}m, {props.seconds}s</span>;
        }
        return <span>Starts in {props.days}d, {props.hours}h, {props.minutes}m, {props.seconds}s</span>;
    };

    const activeRenderer = (props: any) => {
        if (props.days < 1) {
            return <span>Ends in {props.hours}h, {props.minutes}m, {props.seconds}s</span>;
        }

        if (props.days < 1 && props.hours < 1) {
            return <span>Ends in {props.minutes}m, {props.seconds}s</span>;
        }
        return <span>Ends in {props.days}d, {props.hours}h, {props.minutes}m, {props.seconds}s</span>;
    };

    return (
        <>
        <ListItem  sx={{ paddingY: { xs: 0, md: 2 }, paddingX: { xs: 0, md: 2 }, bgcolor: Colors.SecondaryBackground, marginBottom: 2 }}>
            <ListItemButton onClick={handleModalOpen}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h5">{props.proposal.metadata.title}</Typography>
                        <Typography variant="body1" color="text.secondary">{props.proposal.metadata.subTitle}</Typography>
                    </Grid>

                    <Grid item xs={12} md={4} textAlign={{ xs: 'left', md: "right" }}>
                    {props.type === 'active' &&
                        <Typography fontFamily={Fonts.ShareTechMono}>
                            <Countdown 
                                date={props.proposal.account.endTime * 1000}
                                renderer={activeRenderer}>
                                    <Typography>Completed</Typography>
                            </Countdown>
                        </Typography>
                    }
                    
                    {props.type === 'upcoming' &&
                        <Box pt={1}>
                            <Typography fontFamily={Fonts.ShareTechMono}>
                                <Countdown 
                                    date={props.proposal.account.startTime * 1000}
                                    renderer={upcomingRenderer} />
                            </Typography>
                        </Box>   
                    }

                    {props.type === 'completed' &&
                        <Typography fontFamily={Fonts.ShareTechMono}>Completed</Typography>
                    }

                        {props.type !== 'upcoming' && <Typography color="text.secondary">{totalVotes} vote{totalVotes === 1 ? '' : 's'}</Typography>}
                    </Grid>
                </Grid>
            </ListItemButton>
        </ListItem>

        <Modal
            open={modalOpen}
            onClose={handleModalClose}
        >
            <Box>
                <Box className="close-img" onClick={handleModalClose}>
                        <img src={closeImg} />
                </Box>
                <Box sx={largeModalStyle}>
                <ProposalCard 
                    proposal={props.proposal} 
                    nfts={props.nfts} 
                    totalVotes={totalVotes}
                    loadProposals={props.loadProposals}
                    getTotalVotes={getTotalVotes} 
                    type={props.type} />
                </Box>
            </Box>
        </Modal>
        </>
    )
  }

export default ProposalListItem;