import { useEffect, useState } from "react";
import * as anchor from "@coral-xyz/anchor";
import Box from "@mui/material/Box";
import { Typography, Grid, Card, CardMedia, CardContent, Modal, Button, Tabs, Tab, Tooltip } from "@mui/material";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { largeModalStyle } from "../utils/styles";
import { getNetworkConnection, fetchProposals, loadProgram, emptyAnchorWallet } from "../utils/utils";
import ProposalList from "./ProposalList";
import Nfts from "./Nfts";
import Lookup from "./Lookup";
import ActiveIcon from "@mui/icons-material/HowToVote";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import CompletedIcon from "@mui/icons-material/Done";
import { useSearchParams } from "react-router-dom";
import { isActive, isUpcoming, isCompleted, isHidden } from "../utils/utils";
import SearchIcon from "@mui/icons-material/Search";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ paddingY: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const ws = new WebSocket('wss://atlas-mainnet.helius-rpc.com?api-key=11a6ba1a-3f69-413d-8928-24adbe2dd86a');

function sendRequest(ws: any) {
    const request = {
        jsonrpc: "2.0",
        id: 420,
        method: "transactionSubscribe",
        params: [
            {
                accountInclude: ["9L7x6Eeh4yjFzFbCzNpZdo7nWaHd17oHoWPdBsT95VwG"]
            },
            {
                commitment: "finalized",
                encoding: "base64",
                transactionDetails: "full",
                showRewards: true,
                maxSupportedTransactionVersion: 0
            }
        ]
    };
    ws.send(JSON.stringify(request));
}

const Home = (props: any) => {
    const anchorWallet = useAnchorWallet();
    const connection = getNetworkConnection(30);

    const [value, setValue] = useState(2);
    const [proposals, setProposals] = useState<(Object[] | null)>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [params] = useSearchParams();
    const [isLookupOpen, setIsLookupOpen] = useState<boolean>(false);

    useEffect(() => {
        loadProposals();
        handleTypeParam();
    }, []);

    useEffect(() => {
      loadProposals();
  }, [anchorWallet]);

  useEffect(() => {
    if (proposals && proposals.length > 0) {
      handleProposalParam();
    }
  }, [proposals]);

    const handleTypeParam = () => {
      const type = params.get("type"); 
      const proposalKey = params.get("key"); 

      if (type && !proposalKey) {
        if (type === 'active') {
          setValue(0);
        } else if (type === 'upcoming') {
          setValue(1);
        } else if (type === 'completed') {
          setValue(2);
        }
      }
    }

    useEffect(() => {
      console.log(ws);
      ws.addEventListener('open', () => {
          console.log('WebSocket connection established');
          sendRequest(ws);
      });

      ws.addEventListener('message', async(event) => {
          const messageStr = event.data.toString();
          try {
              const messageObj = JSON.parse(messageStr);
              console.log('Received:', messageObj);
              // if (!isLoading) {
                await loadProposals();
              // }
              
          } catch (e) {
              console.error('Failed to parse JSON:', e);
          }
      })
  }, [ws])

    // need to go to correct tab based on proposal key
    const handleProposalParam = () => {
      const proposalKey = params.get("key"); 

      if (proposals && proposalKey) {
        for (let i = 0; i < proposals?.length; i++) {
          // @ts-ignore
          if (proposals[i] && proposals[i].publicKey && proposalKey === proposals[i].publicKey.toString()) {
            if (isHidden(proposals[i])) {
              continue;
            }

            if (isActive(proposals[i])) {
              setValue(0);
            }

            if (isUpcoming(proposals[i])) {
              setValue(1);
            }

            if (isCompleted(proposals[i])) {
              setValue(2);
            }
          }
        }
      }
    }

    const loadProposals = async() => {
      setIsLoading(true);
      let wallet = (!anchorWallet || !anchorWallet.publicKey) ? emptyAnchorWallet : anchorWallet;

      const proposals = await fetchProposals(wallet);
      // console.log({ proposals });

      setProposals(proposals);
      
      setIsLoading(false);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const valueText = () => {
      if (!value || value === 0) {
        return <Typography variant="body1" fontWeight={700}>ACTIVE</Typography>
      }

      if (value === 1) {
        return <Typography variant="body1" fontWeight={700}>UPCOMING</Typography>
      }

      return <Typography variant="body1" fontWeight={700}>COMPLETED</Typography>
    }

    return (
        <Box mt={3} className="content-box">
            {isLookupOpen && <Lookup proposals={proposals} isOpen={isLookupOpen} setIsLookupOpen={setIsLookupOpen} />}

            <Box display={{ xs: 'flex', md: 'none' }} justifyContent="space-between" mb={3}>
              <Box display="flex" alignItems="center" pt={1}>
              {valueText()}
              </Box>
              <Box display="flex">
              <Box mt={1} mr={1}>

              <Button variant="contained" onClick={() => setIsLookupOpen(true)}><SearchIcon /></Button>
              </Box>
               <Nfts  nfts={props.nfts} setNfts={props.setNfts} />

              </Box>
            </Box>

            <Box display={{ xs: 'none', md: 'flex' }} sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: "space-between" }}>
              <Tabs value={value} onChange={handleChange} >
                  <Tab label="Active" {...a11yProps(0)} />
                  <Tab label="Upcoming" {...a11yProps(1)} />
                  <Tab label="Completed" {...a11yProps(2)} />
              </Tabs>
              <Box display="flex">
              <Box mt={1} mr={1}>
              <Tooltip title="Search for cets who have voted">
              <Button variant="contained" onClick={() => setIsLookupOpen(true)}><SearchIcon /></Button>

              </Tooltip>
              </Box>

              <Nfts  nfts={props.nfts} setNfts={props.setNfts} />

              </Box>
            </Box>

            <Box display={{ xs: 'flex', md: 'none' }} sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: "space-between" }}>
              <Tabs value={value} onChange={handleChange} >
                  <Tab label={<ActiveIcon />} {...a11yProps(0)} />
                  <Tab label={<UpcomingIcon />} {...a11yProps(1)} />
                  <Tab label={<CompletedIcon />} {...a11yProps(2)} />
              </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
              <ProposalList proposals={proposals} type={"active"} nfts={props.nfts} loadProposals={loadProposals} isLoading={isLoading} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ProposalList proposals={proposals} type={"upcoming"} nfts={props.nfts} loadProposals={loadProposals} isLoading={isLoading} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <ProposalList proposals={proposals} type={"completed"} nfts={props.nfts} loadProposals={loadProposals} isLoading={isLoading} />
            </CustomTabPanel>    
          
        </Box>
    );
  };
  
  export default Home;
  