import * as anchor from "@coral-xyz/anchor";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, PublicKey, Keypair, SystemProgram, ComputeBudgetProgram } from "@solana/web3.js";
import idl from "./idl/dao_voting.json";
import moment from "moment";
import { Metaplex, walletAdapterIdentity, Metadata, Nft, Sft } from "@metaplex-foundation/js";
import { ProgramAccount } from "@coral-xyz/anchor";
import { TOKEN_METADATA_PROGRAM_ID, SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID, PAYMENT_ACCOUNT, TREASURY } from "./constants";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { sha256 } from "js-sha256";
import bs58 from 'bs58';

export const PROGRAM_ID = "9L7x6Eeh4yjFzFbCzNpZdo7nWaHd17oHoWPdBsT95VwG";
// export const ADMIN_PUBKEY = new anchor.web3.PublicKey('DAkpz8ywvDqk8WKfBACiF83Nbtcsw36hJJ9mUszaVBrq');
export const ADMIN_PUBKEY = new anchor.web3.PublicKey('CeTsWxrJT6v1JgcFGsg2uMdvmdJxxRLtAZ4T5DvGSjVm');

const emptyKeypair = Keypair.generate();

export const emptyAnchorWallet: AnchorWallet = {
  // @ts-ignore
  signTransaction: () => {
    return undefined;
  },
  // @ts-ignore
  signAllTransactions: () => {
    return undefined;
  },
  publicKey: emptyKeypair.publicKey,
};

export const loadProgram = (
    connection: anchor.web3.Connection,
    wallet: AnchorWallet
  ): anchor.Program => {
    const provider = new anchor.AnchorProvider(connection, wallet, {
      preflightCommitment: "confirmed",
    });
  
    const votingProgramId = new PublicKey(PROGRAM_ID);
    const votingProgramInterface = JSON.parse(JSON.stringify(idl));
  
    const program = new anchor.Program(
      votingProgramInterface,
      votingProgramId,
      provider
    ) as anchor.Program;
  
    return program;
};

export const getNetworkConnection = (timeout = 30) => {
    const rpcUrl = process.env.REACT_APP_SOLANA_RPC_URL ?? 'https://api.devnet.solana.com';

    return new anchor.web3.Connection(rpcUrl, {
      confirmTransactionInitialTimeout: timeout * 1000, // timeout Seconds
      commitment: "finalized",
    });
};

export const fetchNftsByMint = async (nftMints: anchor.web3.PublicKey[], connection: Connection) => {
  const metaplex = new Metaplex(connection);

  const allNfts: (Metadata | Nft | Sft | null)[] = await metaplex.nfts().findAllByMintList({ mints: nftMints });

  let nfts = [];
  for (let i = 0; i < allNfts.length; i++) {
      // @ts-ignore
      const metadata = await fetch(allNfts[i].uri).then((res) => res.json());

      nfts.push({...allNfts[i], json: metadata});
  }

  return nfts;
}

export const fetchNfts = async (wallet: AnchorWallet, connection: Connection) => {
	const walletId = wallet.publicKey!;
	const metaplex = new Metaplex(connection);
// const walletId = new PublicKey('AP4TFdxyAPLvpM5XWZLpK1SjuoczwVKWzZW3DFVqoS6r');

	const allNfts = await metaplex.nfts().findAllByOwner({ owner: walletId });


  let nfts = [];
  for (let i = 0; i < allNfts.length; i++) {
    if (allNfts[i].creators && allNfts[i].creators[0] && allNfts[i].creators[0].address.toString() === process.env.REACT_APP_VERIFIED_CREATOR) {
      
      const metadata = await fetch(allNfts[i].uri).then((res) => res.json());

      nfts.push({...allNfts[i], json: metadata});
    }
  }

  const nftData: (ProgramAccount| null)[] = await getStakedNftData(wallet, nfts);

  let fullNfts = [];

  for (let i = 0; i < nfts.length; i++) {
    let fullNft: any = {...nfts[i], nftData: {} };

    for (let k = 0; k < nftData.length; k++) {
      if (nfts[i].address.toString() === nftData[k]?.account.mint.toString()) {
        fullNft.nftData = nftData[k];
      }
    }

    fullNfts.push(fullNft);
  }

  return fullNfts;
}

export const getStakedNftData = async(
    wallet: AnchorWallet, 
    nfts: (Metadata | Nft | Sft)[]): Promise<(ProgramAccount| null)[]> => {
  const connection = getNetworkConnection(30);
  const anchorProgram = loadProgram(connection, wallet);

  let nftDataPDAs = nfts.map((nft: (Metadata | Nft | Sft)) => {
    const [nftDataPDA, _] = getNftDataPDA(nft.address, anchorProgram);

    return nftDataPDA;
  });

  const accounts: anchor.ProgramAccount[] = await anchorProgram.account.nftData.all();

  return accounts;
}

export const fetchProposals = async(wallet: AnchorWallet) => {
    const connection = getNetworkConnection(30);
    const anchorProgram = loadProgram(connection, wallet);

    const proposals: any = await anchorProgram.account.proposal.all();
    const choices = await fetchChoices(anchorProgram, wallet);

    let metadata = await fetchMetadata(proposals);

    for (let i = 0; i < proposals.length; i++) {
      proposals[i].metadata = metadata[i] ?? {};

      proposals[i].choices = [];

      for (let k = 0; k < choices.length; k++) {
        if (choices[k].account.proposal.toString() === proposals[i].publicKey.toString()) {
          proposals[i].choices.push(choices[k]);
        }
      }

      proposals[i].choices.sort((a: any, b: any) => {
        if (a.metadata.title < b.metadata.title) {
          return 1;
        } else if (b.metadata.title < a.metadata.title) {
          return -1;
        }
  
        return 0;
      });
    }

    return proposals.sort((a: any, b: any) => {
      if (a.account.startTime > b.account.startTime) {
        return -1;
      } else if (b.account.startTime > a.account.startTime) {
        return 1;
      }

      return 0;
    });
}

export const fetchChoices = async(anchorProgram: anchor.Program, wallet: AnchorWallet) => {
    const choices: any = await anchorProgram.account.choice.all();
    let metadata = await fetchMetadata(choices);

    for (let i = 0; i < choices.length; i++) {
      choices[i].metadata = metadata[i];
    }

    return choices;
}

export const fetchVoteCountForChoice = async(wallet: AnchorWallet, choiceKey: anchor.web3.PublicKey) => {
  const connection = getNetworkConnection(30);
  const anchorProgram = loadProgram(connection, wallet);

  const votingDiscriminator = Buffer.from(sha256.digest('account:Vote')).slice(0, 8);
  const votingProgramId = new anchor.web3.PublicKey(PROGRAM_ID);

  const accounts = await anchorProgram.provider.connection.getProgramAccounts(
    votingProgramId,
    {
      dataSlice: { offset: 0, length: 0 }, // fetch without any data
      filters: [
        { memcmp: { offset: 0, bytes: bs58.encode(votingDiscriminator) } }, // ensure it's a Vote account.
        { memcmp: { offset: 112, bytes: choiceKey.toBase58() } }
      ],
    }
    );

  return accounts.length;
}

export const fetchStakingDataForWallet = async(anchorProgram: anchor.Program, walletKey: anchor.web3.PublicKey) => {
  const filters = [
    {
      memcmp: {
        offset: 49,
        bytes: walletKey.toBase58(),
      },
    },
  ];

  return await anchorProgram.account.nftData.all(filters);
}

export const fetchStakingDataForMint = async(anchorProgram: anchor.Program, mintKey: anchor.web3.PublicKey) => {
  const filters = [
    {
      memcmp: {
        offset: 8,
        bytes: mintKey.toBase58(),
      },
    },
  ];

  return await anchorProgram.account.nftData.all(filters);
}

export const fetchVotingDataForMint = async(anchorProgram: anchor.Program, mintKey: anchor.web3.PublicKey) => {
  const filters = [
    {
      memcmp: {
        offset: 40,
        bytes: mintKey.toBase58(),
      },
    },
  ];

  return await anchorProgram.account.vote.all(filters);
}

export const fetchVoteCountForProposalOld = async(wallet: AnchorWallet, proposalKey: anchor.web3.PublicKey) => {
  console.log(`made it in here`)
  const connection = getNetworkConnection(30);
  const anchorProgram = loadProgram(connection, wallet);

  const votingDiscriminator = Buffer.from(sha256.digest('account:Vote')).slice(0, 8);
  const votingProgramId = new anchor.web3.PublicKey(PROGRAM_ID);

  const accounts = await anchorProgram.provider.connection.getProgramAccounts(
    votingProgramId,
    {
      dataSlice: { offset: 0, length: 0 }, // Fetch without any data.
      filters: [
        { memcmp: { offset: 0, bytes: bs58.encode(votingDiscriminator) } }, // Ensure it's a Vote account.
        { memcmp: { offset: 80, bytes: proposalKey.toBase58() } }
      ],
    }
    );

  return accounts.length;
}

export const fetchVoteCountForProposal = async(choices: any[]) => {
  let count = 0;

  for (let i = 0; i < choices.length; i++) {
    if (choices[i].account.tally) {
      count += choices[i].account.tally.toNumber();
    }
  }
  
  return count;
}

export const fetchVoteForMint = async(anchorProgram: anchor.Program, mintKey: anchor.web3.PublicKey, proposalKey: anchor.web3.PublicKey) => {
  const filters = [
    {
      memcmp: {
        offset: 40,
        bytes: mintKey.toBase58(),
      },
    },
    {
      memcmp: {
        offset: 80,
        bytes: proposalKey.toBase58(),
      },
    },
  ];

  return await anchorProgram.account.vote.all(filters);
}

export const fetchVotesForOwner = async(anchorProgram: anchor.Program, ownerKey: anchor.web3.PublicKey) => {
  const filters = [
    {
      memcmp: {
        offset: 8,
        bytes: ownerKey.toBase58(),
      },
    },
  ];

  return await anchorProgram.account.vote.all(filters);
}

export const fetchVotesForProposal = async(wallet: AnchorWallet, proposalKey: anchor.web3.PublicKey) => {
  const connection = getNetworkConnection(30);
  const anchorProgram = loadProgram(connection, wallet);

  const filters = [
    {
      memcmp: {
        offset: 80,
        bytes: proposalKey.toBase58(),
      },
    },
  ];

  return await anchorProgram.account.vote.all(filters);
}

const fetchMetadata = async(accountData: []) => {
  let metadataPromises = accountData.map((d: any) => fetch(d.account.metadataUri));
  return await Promise.all(metadataPromises)
    .then(results => Promise.all(results.map((r: any) => r ? r.json() : [])));
}

export const getProposalPDA = (title: string, program: anchor.Program) => {
  return (
    anchor.web3.PublicKey.findProgramAddressSync(
    [Buffer.from("proposal"), Buffer.from(title)],
    program.programId
  )
  );
}

export const getChoicePDA = (title: string, proposal: anchor.web3.PublicKey, program: anchor.Program) => {
  return (
    anchor.web3.PublicKey.findProgramAddressSync(
    [Buffer.from("choice"), proposal.toBuffer(), Buffer.from(title)],
    program.programId
  )
  );
}

export const getNftDataPDA = (mint: anchor.web3.PublicKey, program: anchor.Program) => {
  return (
    anchor.web3.PublicKey.findProgramAddressSync(
    [Buffer.from("nft"), mint.toBuffer()],
    program.programId
  )
  );
}

export const getVotePDA = (proposal: anchor.web3.PublicKey, mint: anchor.web3.PublicKey, program: anchor.Program) => {
  return (
    anchor.web3.PublicKey.findProgramAddressSync(
    [Buffer.from("vote"), proposal.toBuffer(), mint.toBuffer()],
    program.programId
  )
  );
}

export const buildVoteInstruction = async(anchorProgram: anchor.Program, wallet: AnchorWallet, choiceData: any, nftMint: anchor.web3.PublicKey) => {
  const [nftDataPDA, nftDataBump] = await getNftDataPDA(nftMint, anchorProgram);
    
  const nftMetadata = await getMetadataAccount(nftMint);
  const nftTokenAccount = await getTokenAccount(wallet.publicKey, nftMint);

  const [proposalPDA, proposalPDABump] = await getProposalPDA(choiceData.proposalTitle, anchorProgram);
  const [choicePDA, choicePDABump] = await getChoicePDA(choiceData.choiceTitle, proposalPDA, anchorProgram);
  const [votePDA, votePDABump] = getVotePDA(proposalPDA, nftMint, anchorProgram);
  // console.log(nftMint.toString())
  const ix = await anchorProgram.methods.vote(nftDataBump
    ).accounts({
      vote: votePDA,
      choice: choicePDA,
      proposal: proposalPDA,
      nftMint: nftMint,
      nftMetadata: nftMetadata,
      nftTokenAccount: nftTokenAccount,
      nftData: nftDataPDA,
      paymentAcct: PAYMENT_ACCOUNT,
      treasury: TREASURY,
      admin: ADMIN_PUBKEY,
      systemProgram: anchor.web3.SystemProgram.programId,
      tokenProgram: TOKEN_PROGRAM_ID,
      nftProgramId: TOKEN_METADATA_PROGRAM_ID,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
  }).instruction()
// console.log({ix});
  return ix;
}

export const sendAndConfirmTransactionWithRetries = async (
  connection: Connection,
  transaction: anchor.web3.Transaction
): Promise<string | null> => {
  console.log(`transaction`, transaction);
  const TX_RETRY_INTERVAL = 2000;


  let blockhashResult = await connection.getLatestBlockhash({
    commitment: "confirmed",
  });

  let txSignature = null;
  let confirmTransactionPromise = null;
  let confirmedTx = null;

  const signatureRaw = transaction.signatures[0].signature;
  if (!signatureRaw) {
    throw new Error("Signature not found");
  }

  txSignature = bs58.encode(new Uint8Array(signatureRaw));

  let txSendAttempts = 1;
  try {
    // confirmTransaction throws error, handle it
    confirmTransactionPromise = connection.confirmTransaction(
      {
        signature: txSignature,
        blockhash: blockhashResult.blockhash,
        lastValidBlockHeight: blockhashResult.lastValidBlockHeight,
      },
      "confirmed"
    );

    await connection.sendRawTransaction(transaction.serialize(), { skipPreflight: true, maxRetries: 0 });

    confirmedTx = null;
    while (!confirmedTx) {
      confirmedTx = await Promise.race([
        confirmTransactionPromise,
        new Promise((resolve) =>
          setTimeout(() => {
            resolve(null);
          }, TX_RETRY_INTERVAL)
        ),
      ]);
      if (confirmedTx) {
        break;
      }

      await connection.sendRawTransaction(transaction.serialize(), { skipPreflight: true, maxRetries: 0 });

      console.log(`${new Date().toISOString()} Tx not confirmed after ${TX_RETRY_INTERVAL * txSendAttempts++}ms, resending`);
    }
  } catch (error) {
    console.error(error);
  }

  if (!confirmedTx) {
    console.log(`${new Date().toISOString()} Transaction failed`);
    throw new Error("Transaction failed");
  }

  console.log(`${new Date().toISOString()} Transaction successful`);

  return txSignature;
}

export const sendAndConfirmTransactionListCustom1 = async (
  wallet: AnchorWallet,
  connection: anchor.web3.Connection,
  transactionList: anchor.web3.Transaction[]
) => {
  const recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
  const transactionWithBlockhashList = transactionList.map((transaction) => {
    transaction.recentBlockhash = recentBlockhash;
    transaction.feePayer = wallet.publicKey;
    return transaction;
  });

  for (let i = 0; i < transactionWithBlockhashList.length; i++) {
    console.log(`transaction`, transactionWithBlockhashList[i]);
    const sim = await connection.simulateTransaction(transactionWithBlockhashList[i]);
    console.log(`simulation`, sim)
  }

  if (wallet.signAllTransactions) {
    const signedTransactionList = await wallet.signAllTransactions(
      transactionWithBlockhashList
    );
    const sendPromises = signedTransactionList.map(
      async (transaction) =>
        await sendAndConfirmTransactionWithRetries(connection, transaction)
    );
    await Promise.all(sendPromises);
  } else {
    for (const transaction of transactionWithBlockhashList) {
      const signedTransaction = await wallet.signTransaction(transaction);
      await sendAndConfirmTransactionWithRetries(connection, signedTransaction);
    }
  }
}

export const sendAndConfirmTransactionWithRetries_old = async (
  connection: Connection,
  transaction: anchor.web3.Transaction
) => {
  for (let i = 0; i <= 20; i++) {
    try {
      let txSign = undefined;
      try {
        txSign = await connection.sendRawTransaction(
          transaction.serialize(),
          {}
        );
      } catch (error) {
        console.log({ serializationError: error });
        throw error;
      }

      console.log(`Transaction sent(${i}): ${txSign}`);
      const result = await connection.confirmTransaction(txSign, "finalized");
      console.log({ result });
      break;
    } catch (error) {
      // @ts-ignore
      const errorMessage = error?.message;
      if (errorMessage) {
        if (
          errorMessage.includes("This transaction has already been processed")
        ) {
          break;
        }
        if (errorMessage.includes("Blockhash not found")) {
          throw error;
        }
      }
      // @ts-ignore
      console.log({ error, errorMessage: error?.message });
      if (i === 20) {
        throw error;
      }
    }
  }
};

export const getMetadataAccount = async (
  mint: anchor.web3.PublicKey
): Promise<anchor.web3.PublicKey> => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [
        Buffer.from("metadata"),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0];
};

export const getTokenAccount = async function (
  wallet: anchor.web3.PublicKey,
  mint: anchor.web3.PublicKey
) {
  return (
      await anchor.web3.PublicKey.findProgramAddress(
          [wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
          SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
      )
  )[0];
};

export const formatTimestamp = (timestamp: any) => {
    return moment(timestamp * 1000).format('MM/DD/YYYY h:mma');
}

export const numVotes = (proposal: any) => {
  let numVotes = 0;
  
  for (let i = 0; i < proposal.choices.length; i++) {
      if (proposal.choices[i].tally) {
          numVotes += proposal.choices[i].tally.toNumber();
      }
  }

  return numVotes;
}


export const isActive = (proposal: any): boolean => {
  const start = proposal.account.startTime.toNumber();
  const end = proposal.account.endTime.toNumber();
  const now = Date.now() / 1000;

  return start <= now && end > now;
}

export const isUpcoming = (proposal: any): boolean => {
  const start = proposal.account.startTime.toNumber();
  const now = Date.now() / 1000;

  return start > now;
}

export const isCompleted = (proposal: any): boolean => {
  const start = proposal.account.startTime.toNumber();
  const end = proposal.account.endTime.toNumber();
  const now = Date.now() / 1000;

  return start < now && end < now;
}

export const isHidden = (proposal: any): boolean => {
  return proposal.account.isHidden;
}

export const linkify = (text: string) => {
  var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return text.replace(exp, "<a style='color: white;' href='$1' target='_blank'>$1</a>");
}