import { useState, useEffect } from "react";

import * as anchor from "@coral-xyz/anchor";
import { Box, Card, CardHeader, CardContent, CardMedia, Modal, Chip, Typography, Link, List, ListItem, ListItemAvatar, Avatar, ListItemButton, ListItemText, IconButton, Stack, Button, Grid, Paper, Tooltip, TextField } from "@mui/material";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { loadProgram, getNetworkConnection, buildVoteInstruction, sendAndConfirmTransactionListCustom1, fetchStakingDataForWallet, fetchVoteForMint, emptyAnchorWallet, fetchVotesForOwner, fetchNftsByMint, fetchStakingDataForMint } from "../utils/utils";
import { COLLECTION_SIZE } from "../utils/constants";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { ComputeBudgetProgram, PublicKey, Transaction } from '@solana/web3.js'
import { toast } from "react-hot-toast";
import moment from "moment";
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PDFIcon from '@mui/icons-material/PictureAsPdf';
import DocumentIcon from '@mui/icons-material/Article';
import WebsiteIcon from '@mui/icons-material/Web';
import { green, red } from '@mui/material/colors';
import { Colors } from "../utils/styles/DefaultTheme";
import Countdown from 'react-countdown';
import WinnerIcon from '@mui/icons-material/CheckCircle';
import LoserIcon from '@mui/icons-material/Cancel';
import YesIcon from '@mui/icons-material/Check';
import NoIcon from '@mui/icons-material/Close';
import { ProgramAccount } from "@coral-xyz/anchor";
import { modalStyle, largeModalStyle } from "../utils/styles";
// import VoteAnimation from "../assets/images/vote_animation.gif";
import { linkify, fetchVotesForProposal } from "../utils/utils";
import closeImg from "../assets/images/close.png";

const SHDW_PREFIX = `https://img-cdn.magiceden.dev/rs:fit:640:0:0/plain/https://shdw-drive.genesysgo.net/3tPEmShThSrDVM364dUJPLjKCQMGScdPEP3XxgWgN2Xo`;

const ProposalCard = (props: any) => {
    const proposal = props.proposal;
    const nfts = props.nfts;
    // console.log(`proposal key`, proposal.publicKey.toString());
    for (let i = 0; i < proposal.choices.length; i++) {
        console.log(`choice`, proposal.choices[i].account.title, proposal.choices[i].publicKey.toString())
    }
    const [mints, setMints] = useState<(Object | null)[]>([]);
    const [mintVotes, setMintVotes] = useState<(anchor.ProgramAccount | null)[]>([]);
    const [soldNftVotes, setSoldNftVotes] = useState<any[]>([]);
    const [voteChoice, setVoteChoice] = useState<string>('');
    const [voteChoiceObj, setVoteChoiceObj] = useState({});

    const [isVoting, setIsVoting] = useState<boolean>(false);
    const [isCasting, setIsCasting] = useState<boolean>(false);
    const [voteHistoryModalOpen, setVoteHistoryModalOpen] = useState<boolean>(false);
    const [allVotes, setAllVotes] = useState<any[]>([]);

    const anchorWallet = useAnchorWallet();
    const connection = getNetworkConnection(30);

    // console.log(`my nfts`, nfts);
    // console.log({ proposal });

    useEffect(() => {
        if (anchorWallet && anchorWallet.publicKey) {
            getEligibleNfts();
        }
    }, [anchorWallet]);

    useEffect(() => {
        if (anchorWallet && anchorWallet.publicKey) {
            getEligibleNfts();
        }
    }, []);

    useEffect(() => {
        if (anchorWallet && anchorWallet.publicKey) {
            getEligibleNfts();
        }
    }, [props.nfts]);

    useEffect(() => {
        if (voteHistoryModalOpen) {
            getVotes();
       }
    }, [voteHistoryModalOpen]);

    const handleVoteModalHistoryClose = () => {
        setVoteHistoryModalOpen(false);
    }

    const getVotes = async() => {
        let wallet = (!anchorWallet || !anchorWallet.publicKey) ? emptyAnchorWallet : anchorWallet;

        const votes: any[] = await fetchVotesForProposal(wallet, props.proposal.publicKey);
        for (let i = 0; i < votes.length; i++) {
            for (let k = 0; k < props.proposal.choices.length; k++) {
                if (votes[i].account.choice && props.proposal.choices[k].publicKey) {
                    if (props.proposal.choices[k].publicKey.toString() === votes[i].account.choice.toString()) {
                        votes[i].account.choiceTitle = props.proposal.choices[k].account.title;
                    }
                }
               
            }
        }

        // console.log({votes});
        setAllVotes(votes);
    }

    const handleVoteModalClose = () => {
        return false;
    }

    const numEligibleVotes = () => {
        return mints.length;
    }

    const quorumPercent = () => {
        return (proposal.account.quorum.toNumber() / COLLECTION_SIZE) * 100;
    }

    const isQuorumMet = () => {
        let totalVotes = props.totalVotes;
        let quorum = proposal.account.quorum.toNumber();

        if (quorum === 0) {
            return true;
        }

        return quorum > 0 && totalVotes >= quorum;
    }

    const getWinningChoice = () => {
        let winner;
        let mostVotes = 0;
        for (let i = 0; i < proposal.choices.length; i++) {
            let tally = proposal.choices[i].account.tally.toNumber();
            if (tally > mostVotes) {
                winner = proposal.choices[i];
                mostVotes = tally;
            }
        }

        return winner;
    }

    const VoteButton = (choiceProp: any) => {
        if (props.type === 'completed') {
            if (!isQuorumMet()) {
                return <></>;
            }
            let winningChoice = getWinningChoice();
            if (choiceProp.choice.publicKey.toString() === winningChoice.publicKey.toString()) {
                return <Button variant="outlined" color="success" startIcon={<WinnerIcon />}>{choiceProp.choice.account.tally.toNumber()} vote{choiceProp.choice.account.tally.toNumber() === 1 ? '' : 's'}</Button>;
            }
            return  <Button variant="outlined" color="error" startIcon={<LoserIcon />}>{choiceProp.choice.account.tally.toNumber()} vote{choiceProp.choice.account.tally.toNumber() === 1 ? '' : 's'}</Button>;
        }

        if (!anchorWallet || !anchorWallet.publicKey || props.type === 'upcoming') {
            return <></>;
        }

        // let btnText = isVoting ? `Voting` : `Vote${(numEligibleVotes() === 1 || numEligibleVotes() === 0) ? '' : 's'}`; 
        let btnText = isVoting ? `Voting` : `Vote`; 

        return (
            <Button disabled={numEligibleVotes() === 0 || isVoting} variant="outlined" startIcon={<ThumbUpIcon />} onClick={() => openVoteModal(choiceProp.choice)}>
                {btnText}
            </Button>
        )
    }

    const openVoteModal = (choice: any) => {
        setIsVoting(true);
        setVoteChoice(choice.metadata.title);
        setVoteChoiceObj(choice);
    }

    const castVote = async(choice: any, numVotes: number) => {
        if (!anchorWallet || !anchorWallet.publicKey) {
            return;
        }

        setIsCasting(true);

        const anchorProgram = loadProgram(connection, anchorWallet);

        const choiceData = {
            proposalTitle: proposal.account.title,
            choiceTitle: choice.account.title,
        };

        if (numVotes > mints.length) {
            toast.error('Not enough votes!',
            {
              duration: 10000,
              style: {
                borderRadius: '6px',
                background: '#333',
                color: '#fff',
              },
              position: "top-center"
            });
            return;
        }

        let numMints = numVotes;

        try {
            let txns: Array<Transaction> = [];
            
            for (let i = 0; i < numMints; ) {
                let txn = new Transaction();
                txn.feePayer = anchorWallet.publicKey;

                txn.recentBlockhash = (
                    await connection.getLatestBlockhash("processed")
                ).blockhash;
                const PRIORITY_FEE_IX = ComputeBudgetProgram.setComputeUnitPrice({microLamports: 30_000});
                txn.add(PRIORITY_FEE_IX);

                let times = Math.min(3, numMints - i)

                for (times += i; i < times; i++) {
                    // @ts-ignore
                    const nftMint = new anchor.web3.PublicKey(mints[i]?.mintAddress);
                    const voteIx = await buildVoteInstruction(anchorProgram, anchorWallet, choiceData, nftMint);

                  
                    txn.add(voteIx);
                }
                txns.push(txn);
            }
            console.log({ txns });

            await sendAndConfirmTransactionListCustom1(anchorWallet, connection, txns);
            // toast success
            toast.success('Successfully voted!', { 
                position: "top-center"        
            });
            await getEligibleNfts();
            await props.getTotalVotes();

            setIsVoting(false);
        } catch (e) {
            console.log(e);
            toast.error('Unable to cast vote!',
            {
              duration: 10000,
              style: {
                borderRadius: '6px',
                background: '#333',
                color: '#fff',
              },
              position: "top-center",
            }
          );
        } finally {
            setIsCasting(false);
        }
    }

    const getNumVotesForChoice = (choice: any) => {
        let numVotes = 0;
        for (let i = 0; i < mintVotes.length; i++) {
            if (mintVotes[i]?.account.choice.toString() === choice.publicKey.toString()) {
                numVotes += 1;
            }
        }

        return numVotes;
    }

    const getEligibleNfts = async() => {
        if (!anchorWallet || !anchorWallet.publicKey) {
            return;
        }

        // anchorWallet.publicKey = new PublicKey("5n53mGihGGkDC3LjpvjEpQsmqsxSSx9rHrdwHmny3t8e");

        const anchorProgram = loadProgram(connection, anchorWallet);
        // let wallet = new PublicKey('AP4TFdxyAPLvpM5XWZLpK1SjuoczwVKWzZW3DFVqoS6r');
        // let wallet = new PublicKey('5n53mGihGGkDC3LjpvjEpQsmqsxSSx9rHrdwHmny3t8e');
        // const stakingData = await fetchStakingDataForWallet(anchorProgram, wallet);

        const stakingData = await fetchStakingDataForWallet(anchorProgram, anchorWallet.publicKey);
        const stakingRequired = proposal.account.requiredStakeDays.toNumber() > 0;

        let eligibleNfts = [];
        let votes = [];
        for (let i = 0; i < nfts.length; i++) {
            const mintVote = await fetchVoteForMint(anchorProgram, nfts[i].mintAddress, proposal.publicKey);
            
            if (mintVote.length > 0) {
                // this nft has already voted
                votes.push(mintVote[0]);
                continue;
            }
            console.log(nfts[i].mintAddress.toString());
            let stakingAccount: (anchor.ProgramAccount | undefined) = stakingData.find((stake: any) => stake.account.mint.toString() === nfts[i].mintAddress.toString());

            // let legendaries = [
            //     "4t7DofyqHYdjfwqLKkHQ798MQWfce26RSnuLccuyTHvw",
            //     "7VctQnWtMAEPSgfrkYDFhzPdw7ATKCuBnhKryHAi32wk",
            //     "J2UhB1F7YyGNXdMDvNPyVwNYMzEw9xLznBTdrwbx52Z9",
            //     "77GR2QhBAADxjjeSngqMxziAuxvSD3N1uMPhbBHht9Ar",
            //     "4eenV26jZCtRbbRRRLJ3ZnUzPQkGiJb7sncLVMQnFx3o",
            //     "HRSJYvtv5YUBTjFe7WX87sN3cqVow2jjoPvpMr95YQQj",
            //     "AccFHHRZXmqzWVvYrQXVS1scg17mAAKuC2ZTGg9zdvrz",
            //     "5z1G4dmR87WNbFESaoY3hBwvqXJtwtcDt36NnS96i6Ba",
            //     "28iW8ThMyd9ix2R1aMa2B6XeiQFqtxh4iTeP6Phroe4G",
            //     "C3pLGsYufLi5QzUDV2WYt3GK1YTJxgc2mF9YXcXszZnV",
            //     "9TqStZh3j7trsMpZoWPh5eLecn3vuQ1gpL4AaTf6Gjhe",
            //     "Hfa6ipB6vPhq9Q2ftBNY9qzTkpYF2aHztAbVtNDeHXit",
            //     "GH7wF9zcHthiFhHgxNjmWNhMqDsYyrmhALcuVJMZG5bj",
            //     "2SHETjycuZQQF4ZZCU93qW5hZ74JhxNLh6E5AEqu47kh",                
            // ];

            // if (!stakingAccount && legendaries.indexOf(nfts[i].mintAddress.toString()) !== -1) {
            //     // @ts-ignore
            //     stakingAccount = (await fetchStakingDataForMint(anchorProgram, nfts[i].mintAddress))[0];
            // }

            if (!stakingAccount) {
                // @ts-ignore
                stakingAccount = (await fetchStakingDataForMint(anchorProgram, nfts[i].mintAddress))[0];
            }

            if (stakingAccount) {
                nfts[i].staking = stakingAccount;
            }

            if (!stakingRequired) {
                eligibleNfts.push(nfts[i]);
                continue;
            }

            // not eligible because they don't have staking PDA
            if (!stakingAccount) {
                continue;
            }

            let minStakingTimestamp = Math.floor((Date.now() / 1000) - (proposal.account.requiredStakeDays.toNumber() * 86400));
            
            if (stakingAccount && stakingAccount.account.isStaked && stakingAccount.account.stakedTime.toNumber() < minStakingTimestamp) {
                eligibleNfts.push(nfts[i]);
            }
        }

        const ownerVotes: ProgramAccount[] = await fetchVotesForOwner(anchorProgram, anchorWallet.publicKey);
        const soldVotes = [];

        // console.log({ ownerVotes })
        for (let i = 0; i < ownerVotes.length; i++) {
            if (ownerVotes[i].account.proposal.toString() === proposal.publicKey.toString()) {
                // see if the owner still has the nft
                let isFound = false;
                for (let k = 0; k < nfts.length; k++) {
                    if (ownerVotes[i].account.mint.toString() === nfts[k].mintAddress.toString()) {
                        isFound = true;
                    }
                }

                if (!isFound) {
                    soldVotes.push(ownerVotes[i]);
                }
            }
        }

        let soldMints = soldVotes.map((soldVote: ProgramAccount) => {
            return soldVote.account.mint;
        });

        let soldNfts: any[] = await fetchNftsByMint(soldMints, connection);

        for (let i = 0; i < soldVotes.length; i++) {
            for (let k = 0; k < soldNfts.length; k++) {
                if (soldNfts[k].mintAddress.toString() === soldVotes[i].account.mint.toString()) {
                    soldNfts[k].account = soldVotes[i].account;
                }
            }
        }
        // console.log({ soldNfts });

        console.log({ eligibleNfts });
        // console.log({ votes });
        // for (let i = 0; i < votes.length; i++) {
        //     // @ts-ignore
        //     console.log(`vote mint`, votes[i].account.mint.toString());

        // }
        setSoldNftVotes(soldNfts);
        setMintVotes(votes);
        setMints(eligibleNfts);
    }

    const getLinkIcon = (type: string) => {
        if (type === 'twitter')
            return <TwitterIcon />;
        if (type === 'instagram')
            return <InstagramIcon />;
        if (type === 'linkedin')
            return <LinkedInIcon />;
        if (type === 'pdf')
            return <PDFIcon />;
        if (type === 'document')
            return <DocumentIcon />;
        if (type === 'website')
            return <WebsiteIcon />;
    }

    const getChoiceFromKey = (choiceKey: anchor.web3.PublicKey) => {
        return proposal.choices.find((choice: any) => choice.publicKey.toString() === choiceKey.toString());
    }

    const getNftFromMint = (mint: anchor.web3.PublicKey) => {
        return props.nfts.find((nft: any) => nft.mintAddress.toString() === mint.toString());
    }

    const upcomingRenderer = (props: any) => {
        if (props.days < 1) {
            return <span>Starts in {props.hours}h, {props.minutes}m, {props.seconds}s</span>;
        }

        if (props.days < 1 && props.hours < 1) {
            return <span>Starts in {props.minutes}m, {props.seconds}s</span>;
        }
        return <span>Starts in {props.days}d, {props.hours}h, {props.minutes}m, {props.seconds}s</span>;
    };

    const activeRenderer = (props: any) => {
        if (props.days < 1) {
            return <span>Ends in {props.hours}h, {props.minutes}m, {props.seconds}s</span>;
        }

        if (props.days < 1 && props.hours < 1) {
            return <span>Ends in {props.minutes}m, {props.seconds}s</span>;
        }
        return <span>Ends in {props.days}d, {props.hours}h, {props.minutes}m, {props.seconds}s</span>;
    };

    const isVideo = (url: string) => {
        return url.indexOf('youtube') !== -1 || url.indexOf('vimeo') !== -1;
    }

    return (
        <>
      <Card variant="outlined" sx={{ padding: 1 }}>
        <Box display={{ xs: "block", md: "flex" }} justifyContent="space-between" alignItems="center" marginX={{ xs: 0, md: 2 }}>
            <CardHeader sx={{ paddingLeft: { xs: 2, md: 0 } }} 
                        title={<>
                        {proposal.metadata.title}
                        </>} />
            
            <Box display={{ xs: 'flex', md: 'none' }}>
            {(proposal.metadata.imageUrl && !isVideo(proposal.metadata.imageUrl)) && <Paper variant="outlined" sx={{ marginX: 2 }}>
            <img width="100%" src={proposal.metadata.imageUrl} />
            </Paper>}

        {(proposal.metadata.imageUrl && isVideo(proposal.metadata.imageUrl)) && 
          <CardMedia
            src={proposal.metadata.imageUrl}
            height="500"
            component="iframe" />}
            </Box>

            {props.type !== 'upcoming' && <Box marginX={{ xs: 2, md: 0 }} marginTop={{ xs: 2, md: 0 }}>
                {(props.type === 'completed' && proposal.account.quorum.toNumber() > 0 && !isQuorumMet()) && <Chip
                    variant="filled" label="No Quorum" color="warning" sx={{ display: { xs: 'flex', md: 'inline-flex' } }}
                    />}

                {(props.type === 'completed' && isQuorumMet()) && <Chip
                    variant="filled" label="Quorum" sx={{ display: { xs: 'flex', md: 'inline-flex' } }}
                    />}

            {props.type === 'active' &&
            <Chip variant="filled"  sx={{ display: { xs: 'flex', md: 'inline-flex' } }} label={<Countdown 
                date={props.proposal.account.endTime * 1000}
                renderer={activeRenderer}><Typography>Completed</Typography></Countdown>} />
            }

            <Chip variant="filled" label={<Typography variant="body2">Total Vote Count: {props.totalVotes}</Typography>} sx={{ display: { xs: 'flex', md: 'inline-flex' }, marginLeft: { xs: 0, md: 2 }, marginTop: { xs: 2, md: 0 }, marginBottom: { xs: 3, md: 0 }  }} />
            </Box>}

            {props.type === 'upcoming' && <Box marginX={{ xs: 2, md: 0 }} marginTop={{ xs: 2, md: 0 }}>
            <Chip variant="filled" label={<Countdown 
                date={props.proposal.account.startTime * 1000}
                renderer={upcomingRenderer} />} />
            </Box>}

            
        </Box>
        <Box display={{ xs: 'none', md: 'flex' }}>

        {(proposal.metadata.imageUrl && !isVideo(proposal.metadata.imageUrl)) && <Paper variant="outlined" sx={{ marginX: 2, border: 0 }}>
            <img width="100%" src={proposal.metadata.imageUrl} />
        </Paper>}

        {(proposal.metadata.imageUrl && isVideo(proposal.metadata.imageUrl)) && 
          <CardMedia
            src={proposal.metadata.imageUrl}
            height="500"
            component="iframe" />}
        </Box>

        <CardContent>
            <Box>
                <Typography variant="h6">Summary</Typography>
            </Box>
            <Box sx={{ border: Colors.Border }} p={2} mt={1}>
                <Stack direction="row">
                    <Typography variant="body1" >
                        {proposal.metadata.subTitle}
                    </Typography>
                </Stack>

                <Typography variant="body2" color="text.secondary">
                <div
                        dangerouslySetInnerHTML={{__html: linkify(proposal.metadata.description)}}
                />

                {/* {linkify(proposal.metadata.description)}
                {proposal.metadata.description} */}
                </Typography>

                <Box mt={3}>
                    {proposal.metadata.links && proposal.metadata.links.map((link: any, index: number) => <>
                        <Button href={link.url} target="_blank" startIcon={getLinkIcon(link.type)} key={"link-"+index} sx={{ textTransform: 'none' }}>
                            {link.name}
                        </Button>
                    </>)}
                </Box>
            </Box>
        <Box mt={3}>
            <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">Choices</Typography>
            {props.type === 'completed' && <Button color="info" size="small" variant="contained" onClick={() => setVoteHistoryModalOpen(true)}>Show Votes</Button>}
            </Box>
        </Box>

        <Box sx={{ border: Colors.Border }} paddingX={2} mt={1}>
            <List className={props.type !== 'active' ? 'inactive-choice-list' : ''} sx={{
                '& .MuiListItem-root:hover': {
                    bgcolor: Colors.SecondaryBackgroundHover,
                },
            }}>
                {proposal.choices && proposal.choices.map((choice: any, index: number) =>
                    <ListItem
                        sx={{ bgcolor: Colors.SecondaryBackground, paddingY: 1, marginY: 2 }}
                        
                        alignItems={choice.metadata.subTitle ? "flex-start" : "center"}
                        secondaryAction={<Box display={{ xs: 'none', md: 'block' }}><VoteButton choice={choice} /></Box>}>
                        {choice.metadata.imageUrl && <ListItemAvatar sx={{ display: { xs: 'none', md: 'block' } }}>
                            <Avatar alt={choice.metadata.title} src={choice.metadata.imageUrl} />
                            </ListItemAvatar>}

                            {(choice.metadata.title === 'Yes' && !choice.metadata.imageUrl) && 
                      <ListItemAvatar sx={{ display: { xs: 'none', md: 'block' } }}>
                      <Avatar sx={{ bgcolor: green[400] }}>
                        <YesIcon />
                      </Avatar>
                     </ListItemAvatar>}
                      
                     {(choice.metadata.title === 'No' && !choice.metadata.imageUrl) && 
                      <ListItemAvatar sx={{ display: { xs: 'none', md: 'block' } }}>
                      <Avatar sx={{ bgcolor: red[400] }}>
                        <NoIcon />
                      </Avatar>
                     </ListItemAvatar>}

                        <Box sx={{ maxWidth: { xs: '100%', md: '70%' } }}>
                            <Box display={{ xs: "flex", md: 'none' }} marginY={1}>
                                <img width="100%" src={choice.metadata.imageUrl} />
                            </Box>
                        <ListItemText 
                            primary={
                                <Box display="flex">
                                    <Typography fontWeight={500}>{choice.metadata.title}</Typography>
                                </Box>
                            } 
                            secondary={<Typography variant="body2" fontStyle="italic" color={Colors.SubTitle}>{choice.metadata.subTitle}</Typography>} />
                        <Typography variant="body2" color={Colors.Description}>{choice.metadata.description}</Typography>

                        <Box mt={1}>
                            {choice.metadata.links && choice.metadata.links.map((link: any, index: number) => <>
                                
                                <Button href={link.url} target="_blank" startIcon={getLinkIcon(link.type)} key={"link-"+index} sx={{ textTransform: 'none' }}>
                                    {link.name}
                                </Button>
                            </>)}
                        </Box>
                        <Box display={{ xs: 'block', md: 'none' }} marginTop={2}><VoteButton choice={choice} /></Box>

                        </Box>
                    </ListItem>
                )}
            </List>
        </Box>
        <Box mt={3}>
            <Typography variant="h6">Rules</Typography>
        </Box>
        
        <Box sx={{ border: Colors.Border }} p={2} mt={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2" color="text.secondary">Start Date</Typography>
                    <Typography variant="subtitle2">{moment.unix(proposal.account.startTime.toNumber()).format('MMMM Do YYYY, h:mm a')}</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2" color="text.secondary">End Date</Typography>
                    <Typography variant="subtitle2">{moment.unix(proposal.account.endTime.toNumber()).format('MMMM Do YYYY, h:mm a')}</Typography>
                </Grid>

                {(proposal.account.quorum && proposal.account.quorum.toNumber() > 0) && 
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2" color="text.secondary">Quorum Required</Typography>
                    <Typography variant="subtitle2">{quorumPercent().toFixed(2)}% ({proposal.account.quorum.toNumber()} vote{proposal.account.quorum.toNumber() === 1 ? '' : 's'})</Typography>
                </Grid>
                }

                {(proposal.account.requiredStakeDays && proposal.account.requiredStakeDays.toNumber() > 0) && 
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2" color="text.secondary">Required Stake Time</Typography>
                    <Typography variant="subtitle2">{proposal.account.requiredStakeDays.toNumber()} days</Typography>
                </Grid>
                }
            </Grid>
        </Box>

        {(props.type !== 'upcoming' && anchorWallet && anchorWallet.publicKey) && <>
            <Box mt={3} display="flex" justifyContent="space-between">
                <Typography variant="h6">My Votes</Typography>
                {props.type === 'active' && <Box>
                    <Chip label={`${numEligibleVotes()} vote${numEligibleVotes() === 1 ? '' : 's'} left`} />
                </Box>}
            </Box>
            
            <Box sx={{ border: Colors.Border }} p={2} mt={1}>
                <Grid container spacing={2}>
                    {mintVotes && mintVotes.map((mintVote: any, index: number) => <>
                        {mintVote.account.owner.toString() === anchorWallet.publicKey.toString() && <Grid item xs={6} md={2} key={'myvote-'+index}>
                            <Tooltip title={getChoiceFromKey(mintVote.account.choice).metadata.title}>
                                <img width="100%" src={getNftFromMint(mintVote.account.mint).json.image} />
                            </Tooltip>
                        </Grid>}

                        {mintVote.account.owner.toString() !== anchorWallet.publicKey.toString() && <Grid item xs={6} md={2} key={'myvote-'+index}>
                            <Tooltip title={getChoiceFromKey(mintVote.account.choice).metadata.title + " (vote from another wallet)"}>
                                <img width="100%" src={getNftFromMint(mintVote.account.mint).json.image} className="disabled-nft" />
                            </Tooltip>
                            <Typography align="center" variant="body2" color="text.secondary">{getNftFromMint(mintVote.account.mint).json.name}</Typography>

                        </Grid>}
                    </>)}

                    {soldNftVotes && soldNftVotes.map((soldNftVote: any, index: number) => <>
                         <Grid item xs={6} md={2} key={'myvote-'+index}>
                             <Tooltip title={getChoiceFromKey(soldNftVote.account.choice).metadata.title + " (no longer owned)"}>
                                 <img width="100%" src={soldNftVote.json.image} className="disabled-nft" />
                             </Tooltip>
                            <Typography align="center" variant="body2" color="text.secondary">{soldNftVote.json.name}</Typography>
                        </Grid>
                    </>)}

                    {(!mintVotes.length && !soldNftVotes.length) && <>
                        <Grid item>
                            <Typography align="center">No votes!</Typography>
                        </Grid>
                    </>}
                </Grid>
                
            </Box>
        </>}

      </CardContent>
      </Card>
       
       <Modal
        open={voteHistoryModalOpen}
        onClose={handleVoteModalHistoryClose}
        >
            <Box>
                <Box className="close-img" onClick={handleVoteModalHistoryClose}>
                        <img src={closeImg} />
                </Box>
            
                        <Box sx={largeModalStyle} padding={3}>
                            <Typography variant="h5" mb={5}>{props.proposal.account.title}</Typography>
                            <Grid container spacing={2}>
                                {allVotes.length > 0 && allVotes.map((vote) => <>
                                    <Grid item xs={6} md={3}>
                                        <Tooltip title={vote.account.choiceTitle}>
                                            <Box>
                                                <img width="100%" src={SHDW_PREFIX + `/` + vote.account.mint.toString() + `.png`} loading="lazy" />
                                                <Box display={{ xs: 'block', md: 'none' }}>
                                                    <Typography variant="body2" color="text.secondary">{vote.account.choiceTitle}</Typography>
                                                </Box>
                                            </Box>

                                        </Tooltip>
                                    </Grid>
                                </>)}
                            </Grid>
                        </Box>
                    </Box>
        </Modal>

      <Modal
            open={isVoting}
            onClose={handleVoteModalClose}
        >
            <Box sx={modalStyle}>
                {isCasting && <>
                <Box>
                    <Box mb={2}>
                        <img src={require('../assets/images/CETS_Voting_without_text.gif')} width="100%" />
                    </Box>
                    <Box textAlign="center">
                        <Typography variant="h5">CASTING YOUR FKN VOTE...</Typography>
                        <Typography variant="body1" color="text.secondary"><em>Do not refresh, pessy!</em></Typography>
                    </Box>
                </Box>
                
                </>}
                {!isCasting && <>
                <Box>
                    <Typography variant="h4" mb={3}>Vote Confirmation</Typography>
                    <Typography color="text.secondary">
                        You have <strong>{mints.length} vote{mints.length === 1 ? '' : 's'}</strong> available.
                    </Typography>

                    {/* <Typography color="text.secondary">
                        By confirming the transaction, you are casting <strong>{mints.length} vote{mints.length === 1 ? '' : 's'}</strong> for the following choice:
                    </Typography> */}

                    <Typography mt={2}>You are voting for <strong>{voteChoice}</strong></Typography>
                </Box>
                
                
                <Box display={{ xs: "none", md: "flex" }} justifyContent="space-between" mt={5}>
                    <Button variant="outlined" disabled={isCasting} onClick={() => setIsVoting(false)}>Cancel</Button>

                    <Box>
                        {mints.length > 1 &&
                            <Button disabled={isCasting} variant="contained" sx={{ marginRight: 2 }} onClick={() => castVote(voteChoiceObj, mints.length)}>Cast {mints.length} Votes</Button>
                        }
                        <Button disabled={isCasting} variant="contained" onClick={() => castVote(voteChoiceObj, 1)}>Cast 1 Vote</Button>
                    </Box>

                </Box>
                
                <Box display={{ xs: "block", md: "none" }} mt={5} sx={{ textAlign: "center" }}>
                    <Box mb={3}>
                    <Button variant="outlined" disabled={isCasting} onClick={() => setIsVoting(false)}>Cancel</Button>

                    </Box>

                    <Box mb={3}>
                        {mints.length > 1 &&
                            <Button disabled={isCasting} variant="contained" onClick={() => castVote(voteChoiceObj, mints.length)}>Cast {mints.length} Votes</Button>
                        }
                    </Box>

                    <Box>
                        <Button disabled={isCasting} variant="contained" onClick={() => castVote(voteChoiceObj, 1)}>Cast 1 Vote</Button>
                    </Box>

                </Box>
                </>}
            </Box>
        </Modal>
      </>
    )
  }

export default ProposalCard;