import { useEffect, useState } from "react";
import * as anchor from "@coral-xyz/anchor";
import { Typography, Grid, Card, CardMedia, CardContent, Modal, Button, Box, Stack, TextField, List, ListItem } from "@mui/material";
import { fetchNfts, getNetworkConnection, loadProgram, emptyAnchorWallet, fetchVotingDataForMint } from "../utils/utils";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { wideModalStyle } from "../utils/styles";
import closeImg from "../assets/images/close.png";
import cets from "../assets/cets-mints.json";
import CheckIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/DoDisturbOn";
import SearchIcon from "@mui/icons-material/Search";

const Lookup = (props: any) => {
    const anchorWallet = useAnchorWallet();
    const connection = getNetworkConnection(30);

    const [nftsModalOpen, setNftsModalOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [results, setResults] = useState<anchor.ProgramAccount[]>([]);
    const [cetImage, setCetImage] = useState<string>('');

    const handleNftsModalClose = () => {
        props.setIsLookupOpen(false);
    }

    const handleNftsModalOpen = () => {
        setNftsModalOpen(true);
    }

    useEffect(() => {
        if (props.isOpen) {
            setNftsModalOpen(true);
        }
    }, []);

    const doSearch = async() => {
        setIsLoading(true);

        const mint = getMint(search);
        console.log(mint);
        // @ts-ignore
        if (mint && mint.mint) {
            // @ts-ignore
            const voteData = await loadVoteData(new anchor.web3.PublicKey(mint.mint));
            setCetImage(mint.image);
        }

        setIsLoading(false);

    }

    const handleSearchChange = (e: any) => {
        setSearch(e.target.value);
    }

    const loadVoteData = async(mint: anchor.web3.PublicKey) => {
        let wallet = (!anchorWallet || !anchorWallet.publicKey) ? emptyAnchorWallet : anchorWallet;
        const anchorProgram = loadProgram(connection, wallet);

        const votingData: anchor.ProgramAccount[] = await fetchVotingDataForMint(anchorProgram, mint);
        setResults(votingData);
    }

    const getStakingStatus = (nft: any) => {
        if (nft.stakingData && nft.stakingData.isStaked) {
            const now = Date.now() / 1000;
            const stakedTime = nft.stakingData.stakedTime;

            const stakedDays = ((now - stakedTime) / 86400).toFixed(2);

            return `Staked for ${stakedDays} days`;
        }

        return `Unstaked`;
    }

    const getMint = (number: any) => {
        // @ts-ignore
        for (let i = 0; i < cets.mints.length; i++) {
            // @ts-ignore
            if (cets.mints[i].name.split('Cet #')[1].toString() === number.toString()) {
                // @ts-ignore
                return cets.mints[i];
            }
        }
    }

    const hasVoted = (proposal: anchor.web3.PublicKey) => {
        for (let i = 0; i < results.length; i++) {
            if (proposal.toString() === results[i].account.proposal.toString()) {
                return <CheckIcon />;
            }
        }

        return <CancelIcon />;
    }

    const CetsButton = () => {
        return <Button variant="contained" onClick={handleNftsModalOpen}><SearchIcon /></Button>;
    }

    return (
        <>
      {/* <Box mt={1}>
        <CetsButton />
      </Box> */}
      <Modal
            open={nftsModalOpen}
            onClose={handleNftsModalClose}
        >
            <Box>
                <Box className="close-img" onClick={handleNftsModalClose}>
                        <img src={closeImg} />
                </Box>

                <Box sx={wideModalStyle} padding={3}>
                
                <Box display={{ xs: 'none', md: 'flex' }} my={3}>
                    <TextField size="small" label="Search by Cet #" variant="outlined" disabled={isLoading} value={search} onKeyPress={(e) => {
        if (e.key === 'Enter') {
          doSearch();
        }
      }} onChange={handleSearchChange} />
                    <Button variant="contained" onClick={doSearch} disabled={isLoading} startIcon={<SearchIcon />}>Search</Button>
                </Box>

                <Box display={{ xs: 'block', md: 'none' }} mb={5}>
                    <TextField fullWidth size="small" label="Search by Cet #" variant="outlined" disabled={isLoading} value={search} onKeyPress={(e) => {
        if (e.key === 'Enter') {
          doSearch();
        }
      }} onChange={handleSearchChange} />
                    <Button fullWidth variant="contained" onClick={doSearch} disabled={isLoading} startIcon={<SearchIcon />}>Search</Button>
                </Box>


                <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <img src={cetImage} width="100%" />
                        </Grid>

                {(results.length === 0 && cetImage.length > 0) && <>
                    <Grid item xs={12} md={6}>
                    <List disablePadding>
                    <ListItem>
                    <Typography variant="h5"  display={{ xs: 'none', md: 'block' }}>No votes found!</Typography>
                    <Typography variant="h5" fontSize="1.1rem" display={{ xs: 'block', md: 'none' }}>No votes found!</Typography>

                    </ListItem>
                    </List>
                    </Grid>
                </>}

                {results.length > 0 && <>
                        <Grid item xs={6}>
                            {props.proposals.length && props.proposals.map((proposal: any, index: number) => 
                        <>

                        <List key={`proposal`+index} disablePadding>
                        {!proposal.account.isHidden && 
                            <ListItem>
                            <Box display="flex" >
                                <Typography alignSelf="flex-end">{hasVoted(proposal.publicKey)}</Typography>
                                <Typography display={{ xs: 'none', md: 'block' }} variant="h5" sx={{ marginLeft: 2 }}>{proposal.metadata.title}</Typography>
                                <Typography display={{ xs: 'block', md: 'none' }} fontSize="1.1rem" variant="h5" alignSelf="center" sx={{ marginLeft: 2, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{proposal.metadata.title}</Typography>
                            </Box>
                            </ListItem>
                            }
                        </List>
                        </>
                        )}
                        </Grid>
                </>}
                </Grid>
                </Box>
            </Box>
        </Modal>
      </>
    );
  };
  
  export default Lookup;
  