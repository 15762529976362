import { Typography, Box, List, Grid, ListItem, ListItemButton } from "@mui/material";
import { useEffect, useState } from "react";
import ProposalCard from "./ProposalCard";
import ProposalListItem from "./ProposalListItem";
import { Colors, Fonts } from "../utils/styles/DefaultTheme";
import { isActive, isUpcoming, isCompleted, isHidden } from "../utils/utils";

const ProposalList = (props: any) => {
    const [proposals, setProposals] = useState<(Object[] | null)>([]);

    useEffect(() => {
        const type = props.type;

        const filteredProposals = props.proposals.filter((proposal: any) => {
            if (type === 'active' && isActive(proposal) && !isHidden(proposal)) {
                return proposal;
            }

            if (type === 'upcoming' && isUpcoming(proposal) && !isHidden(proposal)) {
                return proposal;
            }

            if (type === 'completed' && isCompleted(proposal) && !isHidden(proposal)) {
                return proposal;
            }
        });

        setProposals(filteredProposals);
    }, [props.proposals]);

    return (
      <Box>
          <List>
            {proposals && proposals.map((proposal: any, index: number) => 
                <ProposalListItem 
                    proposal={proposal} 
                    nfts={props.nfts} 
                    key={'proposalitem-'+index} 
                    loadProposals={props.loadProposals} 
                    type={props.type} />
            )}

        {(!props.isLoading && proposals?.length === 0) && 
            <ListItem sx={{ paddingY: 2, bgcolor: Colors.SecondaryBackground }}>
            <ListItemButton>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h5">Nothing here!</Typography>
                        {/* <Typography variant="body1" color="text.secondary">{props.proposal.metadata.subTitle}</Typography> */}
                    </Grid>
              </Grid>
              </ListItemButton>
              </ListItem>
              }
          </List>
      </Box>
    );
  };
  
  export default ProposalList;
  