import { WalletMultiButton } from  "@solana/wallet-adapter-react-ui";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import cat from "../assets/images/cat-logo.png";
import catOnly from "../assets/images/cet.svg";

const Header = () => {
    return (
      <Box mt={1}>
          <Box display="flex" justifyContent="space-between">
              <Box display={{ xs: 'none', md: 'flex' }}>
                  <img height="100" src={catOnly} />
              </Box>

              <Box display={{ xs: 'flex', md: 'none' }}>
                  <img height="100" src={catOnly} />
              </Box>

              <Box mt={{ xs: 3.5, md: 2 }}>
                  <WalletMultiButton className="wallet-button" />
              </Box>
          </Box>
      </Box>
    );
  };
  
  export default Header;
  