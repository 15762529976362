import { useEffect, useState } from "react";
import * as anchor from "@coral-xyz/anchor";
import { Typography, Grid, Card, CardMedia, CardContent, Modal, Button, Box, Stack } from "@mui/material";
import { fetchNfts, getNetworkConnection, loadProgram, fetchStakingDataForWallet, fetchStakingDataForMint } from "../utils/utils";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { largeModalStyle } from "../utils/styles";
import closeImg from "../assets/images/close.png";
import { PublicKey } from "@solana/web3.js";

const Nfts = (props: any) => {
    const anchorWallet = useAnchorWallet();
    const connection = getNetworkConnection(30);

    const [nftsModalOpen, setNftsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleNftsModalClose = () => {
        setNftsModalOpen(false);
    }

    const handleNftsModalOpen = () => {
        setNftsModalOpen(true);
    }

    useEffect(() => {
        loadNfts();
    }, [anchorWallet]);

    const loadNfts = async() => {
        if (anchorWallet && anchorWallet.publicKey) {
            setIsLoading(true);
            // anchorWallet.publicKey = new PublicKey("5n53mGihGGkDC3LjpvjEpQsmqsxSSx9rHrdwHmny3t8e");
            const nfts: any[] = await fetchNfts(anchorWallet, connection);

            const anchorProgram = loadProgram(connection, anchorWallet);

            // const stakingData: anchor.ProgramAccount[] = await fetchStakingDataForWallet(anchorProgram, anchorWallet.publicKey);
            
            for (let i = 0; i < nfts.length; i++) {
                const mint = nfts[i].mintAddress ?? nfts[i].mint.address;

                const stakingData = await fetchStakingDataForMint(anchorProgram, new PublicKey(mint));

                // for (let k = 0; k < stakingData.length; k++) {
                    // if (nfts[i].mintAddress.toString() === stakingData[k].account.mint.toString()) {
                        nfts[i].stakingData = stakingData[0].account;
                    // }
                // }
            }
            console.log({ nfts });

            props.setNfts(nfts);
            setIsLoading(false);
        }
    }

    const getStakingStatus = (nft: any) => {
        if (nft.stakingData && nft.stakingData.isStaked) {
            const now = Date.now() / 1000;
            const stakedTime = nft.stakingData.stakedTime;

            const stakedDays = ((now - stakedTime) / 86400).toFixed(2);

            return `Staked for ${stakedDays} days`;
        }

        return `Unstaked`;
    }

    const CetsButton = () => {
        if (!anchorWallet || !anchorWallet.publicKey) {
            return <></>;
        }
        return <Button variant="contained" onClick={handleNftsModalOpen}>My Cets</Button>;
        // return <Button variant="contained" onClick={handleNftsModalOpen}>{ props.nfts && props.nfts.length } CET{ props.nfts && props.nfts.length === 1 ? '' : 's'}</Button>;
    }

    return (
        <>
      <Box mt={1}>
        <CetsButton />
      </Box>
      <Modal
            open={nftsModalOpen}
            onClose={handleNftsModalClose}
        >
            <Box>
                <Box className="close-img" onClick={handleNftsModalClose}>
                        <img src={closeImg} />
                </Box>

                <Box sx={largeModalStyle} padding={3}>
                    <Grid container spacing={2}>
                        {(props.nfts && props.nfts.length === 0 && !isLoading) && 
                        <Grid item xs={12} lg={12}>
                            <Box display="flex" justifyContent="center" textAlign="center">
                                <Stack>
                                    <Typography variant="h5">No cets found!</Typography>
                                    <Typography>Go buy some on Tensor, pessy.</Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        }

                        {(isLoading) && 
                        <Grid item xs={12} lg={12}>
                            <Box display="flex" justifyContent="center" textAlign="center">
                                <Stack>
                                    <Typography variant="h5">Loading your cets!</Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        }

                {props.nfts && props.nfts.map((nft: any, index: number) =>
                    <Grid item xs={12} sm={6} md={6} lg={4} key={'nft-'+index}>
                        <Card>
                            <CardMedia
                                component="img"
                                image={nft.json.image}
                                alt={nft.name}
                            />                        
                            <CardContent>
                                <Typography variant="h6">
                                    {nft.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {getStakingStatus(nft)}
                                </Typography>                        
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                </Grid>
                </Box>
            </Box>
        </Modal>
      </>
    );
  };
  
  export default Nfts;
  