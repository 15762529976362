import * as anchor from "@coral-xyz/anchor";

export const COLLECTION_SIZE = 6975;

export const TOKEN_METADATA_PROGRAM_ID = new anchor.web3.PublicKey(
    'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'
  );
export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID =
  new anchor.web3.PublicKey("ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL");

export const PAYMENT_ACCOUNT = new anchor.web3.PublicKey('9TxQd5SqL1Mg54sBrYU6fQGQaSRK1voryWLUJquoyZdC');
export const TREASURY = new anchor.web3.PublicKey('6xYwBbBfSHV9z2UzRoQuZdo5yARyXiz75Xawqw6XxUfh');
